function fullscreen(){
    $windowHeight = ($(window).height())-20;
    $('.intro').height($windowHeight);
}

$(document).ready(function(){

    //fullscreen
    $(window).resize(fullscreen);
    fullscreen();



    // $('.sharesocmed').click(function(e){
    //     e.preventDefault();
    // })
    //
    $('a[href^="#"]').on('click',function (e) {
        e.preventDefault();

        var target = this.hash;
        var $target = $(target);

        $('html, body').stop().animate({
            'scrollTop': $target.offset().top
        }, 900, 'swing', function () {
            window.location.hash = target;
        });
    });


    //$(".fancybox").fancybox({
        //    modal: true,
        //    helpers : { buttons: { } }
        //});
        //
        //$(".filter").on("click", function () {
        //    var $this = $(this);
        //    // if we click the active tab, do nothing
        //    if (!$this.hasClass("active")) {
        //        $(".filter").removeClass("active");
        //        $this.addClass("active"); // set the active tab
        //        var $filter = $this.data("rel"); // get the data-rel value from selected tab and set as filter
        //        $filter == 'all' ? // if we select "view all", return to initial settings and show all
        //            $(".fancybox").attr("data-fancybox-group", "gallery").not(":visible").fadeIn()
        //            : // otherwise
        //            $(".fancybox").fadeOut(0).filter(function () {
        //                return $(this).data("filter") == $filter; // set data-filter value as the data-rel value of selected tab
        //            }).attr("data-fancybox-group", $filter).fadeIn(1000); // set data-fancybox-group and show filtered elements
        //    } // if
        //}); // on
    //
    // $('.swap').click(function(e){
    //     e.preventDefault();
    //     newImage = this.href;
    //     $('.bigImage').fadeOut(500,function(){
    //         $('.bigImage').attr('src',newImage);
    //         $('.bigImage').fadeIn(500);
    //
    //     });
    //
    // })



    //slideshow & carousel
    $('.slide-banner-big').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        arrows:false,
        dots:true,
        fade:true,
        speed:2000,
        pauseOnHover:false,
    });

    $('.slide-banner-small1').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        arrows:true,
        dots:true,
    });

    $('.album-slide').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 3000,
        arrows:false,
        dots:true,
    });


    $('.accomodation-slide').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 4500,
        arrows:true,
        dots:false,
    });

    $('.accomodation-slide-with-dots').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 4500,
        arrows:true,
        dots:true,
    });
});